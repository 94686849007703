import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import appConfig from '../../utils/appConfig';

interface PaymentResponse {
    success: boolean;
    code: string;
    userId: string;
    sum: number;
    email: string;
    confirmationCode: string;
}  

// פונקציה שמטפלת בתגובת התשלום מטרנזילה
function handlePaymentResponse(event: MessageEvent) {
  if (event.origin !== 'https://direct.tranzila.com') {
    // התעלם מהודעות שמגיעות ממקורות לא מוכרים
    return;
  }

  console.log('Received message from Tranzila:', event.data);

  try {
    const response: PaymentResponse = JSON.parse(event.data);
    console.log('Parsed payment response:', response);

    // שליחת התוצאה לשרת באמצעות POST
    sendPaymentResult(response);

  } catch (error) {
    console.error('Error parsing payment response:', error);
  }
}

// דוגמה לפונקציה מטרנזילה ששולחת בקשת POST
function sendPaymentResult(response: PaymentResponse) {
    fetch('https://afternoon.billagan.co.il/api/tranzilaCallback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(response)
    }).then(res => res.json())
      .then(data => console.log('Success:', data))
      .catch((error) => console.error('Error:', error));
}

const CreditcardPay: React.FC = () => {
  const location = useLocation();

  const afternoon = location.state?.afternoon;
  const formData = location.state?.formData;
  const responseData = location.state?.responseData;

  interface Account {
    pay_num?: number; // שדה אופציונלי
  }

  const [account, setAccount] = useState<Account>();
  const [payType, setPayType] = useState('');
  useEffect(() => {
    const storedAccount = localStorage.getItem('account');
  
    if (storedAccount) {
      setAccount(JSON.parse(storedAccount));
    }
  }, []);

  const calculateMonthsToJune = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // מחזיר את החודש הנוכחי כמספר (0-11)
    const currentYear = currentDate.getFullYear();

    // מספר החודשים עד יוני הבא כולל החודש הנוכחי ויוני הבא
    const juneMonth = 5; // 5 מייצג את יוני (0-11)
    const juneYear = currentMonth <= juneMonth ? currentYear : currentYear + 1;

    // חישוב מספר החודשים
    let monthsDifference = (juneYear - currentYear) * 12 + (juneMonth - currentMonth) + 1;

    monthsDifference = monthsDifference > 10 ? 10 : monthsDifference;

    return monthsDifference;
  };
  const { authorityName } = useParams<{ authorityName: string }>();

  const authority = appConfig.authorities.find(auth => auth.en === authorityName);

  const mounts = calculateMonthsToJune();
  const supplier = authority?.tranzilaCode; // יש להחליף לפי שם המסוף שלך
  const currency = 1;
  const cred_type = 8; // סוג עסקה. 8 מייצג תשלומים
  const lang = "il";
  const type = "צהרון"

  let sum = formData ? (afternoon.field22 ? (afternoon.field22 * mounts) : afternoon.Total_annual_price) : responseData.field21;
  sum = formData && formData.parents.maritalStatus === "גרוש" ? sum / 2 : sum;

  const email = formData?.parents.email || responseData.email;
  
  const maxpay = account?.pay_num ?? 5;

  const contact = formData ? `${formData.parents.parentName} ${formData.lastName}` : `${responseData.parentName} ${responseData.lastName}`;
  const ID_child = formData?.studentId || responseData.id_child;
  const user_defined_3 = formData?.parents.email || responseData.email;
  const user_defined_7 = formData ? `תשלום עבור צהרון - ${formData.firstName} ${formData.lastName} ${formData.studentId}` : `תשלום עבור צהרון - ${responseData.firstName} ${responseData.lastName} ${responseData.id_child}` ;
  const user_defined_8 = formData?.studentId || responseData.id_child;
  const pdesc = formData ? `תשלום עבור צהרון - ${formData.firstName} ${formData.lastName} ${formData.studentId}` : `תשלום עבור צהרון - ${responseData.firstName} ${responseData.lastName} ${responseData.id_child}` ;

  const thankYouUrl = appConfig.apiUrls.baseUrl?.replace("/api", "") + "/thankYouPage";
  console.log(thankYouUrl);

  const iframeUrl = `https://direct.tranzila.com/${supplier}/iframenew.php?sum=${sum}&currency=${currency}&cred_type=${cred_type}&maxpay=${maxpay}&lang=${lang}
                     &user_defined_3=${user_defined_3}&user_defined_7=${user_defined_7}&user_defined_8=${user_defined_8}&email=${email}
                     &pdesc=${pdesc}&contact=${contact}&ID_child=${ID_child}&type=${type}&success_url_address=${thankYouUrl}&fail_url_address=${thankYouUrl}&nologo=1`;
                    
  useEffect(() => {
    window.addEventListener('message', handlePaymentResponse);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handlePaymentResponse);
    };
  }, []);

  return (
    <Box sx={{ maxWidth: 400, margin: '0 auto', padding: 2 }}>
      <Typography
        variant="h6"
        sx={{
          marginBottom: 2,
          textAlign: 'center',
          padding: 2,
          backgroundColor: '#f0f4f8',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          border: '1px solid #ddd',
        }}
      >
        <span style={{ fontWeight: 'bold', color: '#333' }}>שם הילד:</span> {formData? formData.firstName+ " " + formData.lastName:responseData.firstName + " " + responseData.lastName}
        <br />
        <span style={{ fontWeight: 'bold', color: '#333' }}>שם המוסד:</span> {formData? afternoon.Name: responseData.mosad2}
        <br />
        <span style={{ fontWeight: 'bold', color: '#333' }}>סכום לתשלום:</span> ₪{sum}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
      <div style={{ width: '370px',backgroundColor:'white', height: '455px', overflow: 'hidden', position: 'relative', border: '1px solid #ddd', borderRadius: '8px' }}>
      <iframe
        src={iframeUrl}
        frameBorder="0"
        scrolling="no"
        title="Tranzila Payment"
        style={{
          width: '460px', // גודל מותאם של ה-iframe
          height: '700px', // גודל מותאם של ה-iframe
          transform: 'scale(0.8)', // שינוי ה-scale כדי להתאים את הגודל לתוך ה-div
          transformOrigin: 'top left',
          position: 'absolute',
          top: '0',
          left: '0',
          marginTop:'50px',
        }}
      ></iframe>
    </div>
      </Box>
    </Box>
  );
};

export default CreditcardPay;
